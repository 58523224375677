import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import Hashtags from '../components/Hashtags';

import { IconVideo, IconText, IconAudio, IconVeranstaltung } from '../components/Icons';

const Post = ({ node, page, searchPage = 'beitraege' }) => (
  <article className="article">
    <Link to={`/${searchPage}/${node.link}`}>
      <div className="post-card">
        {/* {page === 'Themenschwerpunkt' && node.themenschwerpunkt.length > 0 && (
          <h4>#{node.themenschwerpunkt[0].link}</h4>
        )} */}

        <div className="post-card__image">
          <Img
            fluid={node.bvogMainPicture.fluid}
            alt={node.bvogFrontTitel || node.bvogTitel || node.beitragsname}
            style={{ height: '100%', maxHeight: 380 }}
          />
          <div className="post-card__image__top">
            <div className="content-hashtags">
              {node.themenschwerpunkt.map((tag, index) => (
                <span key={index}>#{tag.link}</span>
              ))}
            </div>

            <div className="content-icons">
              {node.bvogContentModules.some(
                content => content.__typename === 'DatoCmsBvogModText'
              ) && <IconText />}
              {node.bvogVeranstaltungen.length > 0 && <IconVeranstaltung />}
              {node.bvogContentModules.some(
                content => content.__typename === 'DatoCmsBvogModVideo'
              ) && <IconVideo />}
              {node.bvogContentModules.some(
                content => content.__typename === 'DatoCmsBvogModPodcast'
              ) && <IconAudio />}
            </div>
          </div>
        </div>

        <div className="post-card__label">
          <div className="post-card__label__wrapper">
            <h3 />
            <h2 className="title title--white">
              {node.bvogFrontTitel || node.bvogTitel || node.beitragsname}
            </h2>
          </div>
        </div>
      </div>

      <span>{node.bvogMainPicture && node.bvogMainPicture.title}</span>
      <div className="post-caption">{node.bvogFrontText && <p>{node.bvogFrontText}</p>}</div>
    </Link>

    <div className="post-card-hashtags">{node.hashtag && <Hashtags hashtag={node.hashtag} />}</div>
  </article>
);

export default Post;
